// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;500;700&family=Nanum+Myeongjo:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;500;700&family=Nanum+Myeongjo:wght@400;700&family=Ole&display=swap');
$body-bg: #fbf8ff;
$primary: #7b56ad;
$body-color: #1a0244;
$table-color: $body-color;

$body-secondary-color:      rgba($body-color, .75);
$secondary:         #f1ebfa;

$body-tertiary-color:       rgba($body-color, .5);
$tertiary:          #e1d9ed;

$btn-hover-bg-tint-amount: 30%;
$font-family-base: 'Montserrat', medium;
// $font-family-base: 'Ole', medium;

html, body {
  min-height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.chart{
  top: 2.5rem;
}

.MuiChartsAxis-tickLabel{
  font-size:18px!important;
}

text.MuiPieArcLabel-root {
  fill: white!important;
}

.chart h2.card-title{
  margin-bottom: -3rem;
}

.underline::after {
  content: '';
  display: block;
  margin-left: 30%;
  margin-top: 1rem;
  position: relative;
  width: 60%;
  border-bottom: 5px solid $primary;
}

.collapsible-text {
  height:20px;
}

.calculator > * {
  margin-bottom: 4rem;
}

//This might need to be in this order... not sure
@import 'bootstrap/scss/bootstrap';

.modal-backdrop:nth-child(2n-1) {
  opacity : 0;
}

.logo {
  width: 60px;
}

.navlink {
  text-decoration: none;
  color: $body-color;
  transition: color 300ms;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  font-size: 1.2rem;
  &:after{
    content:'';
    display:block;
    width: 0;
    border-bottom: 3px solid $primary;
    transition: width 300ms;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  &:hover {
    color: $primary;
    text-decoration: none;
    &:after{
      width: 100%;
    }
  }
}

@media ( min-width: 700px){
  .form-select {
    width: max-content;
    min-width: 100%;
  }
}

// @media ( max-width: 700px) {
//   tr :first-child {
//     display: block;
//   }
//   tr :nth-child(2) {
//     display: block;
//   }
// }

  
